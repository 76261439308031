var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoader
        ? _c("Loader", { attrs: { loading: _vm.isLoader } })
        : _c(
            "div",
            {
              staticClass: "products flex mr-5 mb-5 modal-backdrop",
              class: _vm.isTable ? "flex-row" : "flex-col"
            },
            [
              _c(
                "div",
                {
                  staticClass: "save-backdrop",
                  class: _vm.isContinueSave ? "open" : ""
                },
                [
                  _vm.isContinueSave
                    ? _c("div", { staticClass: "continue-save" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "confirmation-text my-20 pt-5 font-bold text-white"
                          },
                          [
                            _vm._v(
                              " Changes made will override the current product details "
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-row flex flex-row justify-between"
                          },
                          [
                            _c(
                              "Button",
                              {
                                staticClass: "cancelBtn",
                                attrs: { buttonType: "grey" },
                                on: {
                                  click: function($event) {
                                    return _vm.discardChanges()
                                  }
                                }
                              },
                              [_vm._v(" Cancel ")]
                            ),
                            _c(
                              "Button",
                              {
                                attrs: { buttonType: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateProductData()
                                  }
                                }
                              },
                              [_vm._v(" OK ")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ),
              _c("div", { staticClass: "filters-container" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center justify-evenly pb-4",
                    class: _vm.isProductType === "edit" ? "-ml-100" : ""
                  },
                  [
                    _c("div", { staticClass: "flex flex-row items-center" }, [
                      _c(
                        "label",
                        { staticClass: "label font-bold text-right pr-4" },
                        [
                          _vm._v("Brand:"),
                          _c("span", { staticClass: "text-red-600" }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "select-box user-input relative" },
                        [
                          _vm.isBrandRefresh
                            ? _c("AutoCompleteDropdown", {
                                attrs: {
                                  options: _vm.brands,
                                  isProductType: _vm.isProductType,
                                  disabled: _vm.isProductType === "edit"
                                },
                                on: { "selected-item": _vm.setSelectedBrand },
                                model: {
                                  value: _vm.selectedBrand,
                                  callback: function($$v) {
                                    _vm.selectedBrand = $$v
                                  },
                                  expression: "selectedBrand"
                                }
                              })
                            : _vm._e(),
                          _c("Loader", {
                            attrs: {
                              loading: _vm.isBrandLoader,
                              size: _vm.dataLoaderSize
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "flex flex-row items-center" }, [
                      _c(
                        "label",
                        {
                          staticClass: "label font-bold text-right pr-4",
                          class: { hidden: _vm.isTable }
                        },
                        [
                          _vm._v("Product Name:"),
                          _c("span", { staticClass: "text-red-600" }, [
                            _vm._v("*")
                          ])
                        ]
                      ),
                      _vm.isProductsRefresh
                        ? _c(
                            "div",
                            { staticClass: "select-box user-input relative" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedProduct,
                                    expression: "selectedProduct"
                                  }
                                ],
                                attrs: {
                                  type: "text",
                                  title: _vm.selectedProduct,
                                  disabled: _vm.isProductType === "edit",
                                  maxlength: "250"
                                },
                                domProps: { value: _vm.selectedProduct },
                                on: {
                                  keyup: function($event) {
                                    return _vm.updateVariationName(
                                      $event.target.value
                                    )
                                  },
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.selectedProduct = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        : _vm._e()
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center justify-evenly pb-4",
                    class: _vm.isProductType === "edit" ? "-ml-100" : ""
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "product-startdate flex flex-row items-center"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "label font-bold pr-4 text-right" },
                          [
                            _vm._v("Product Start Date:"),
                            _c("span", { staticClass: "text-red-600" }, [
                              _vm._v("*")
                            ])
                          ]
                        ),
                        _vm.isProductsRefresh
                          ? _c(
                              "div",
                              { staticClass: "date-container" },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    format: "YYYY-MM-DD",
                                    valueType: "format",
                                    disabled: _vm.isProductType === "edit"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.updateVariationStartDate()
                                    }
                                  },
                                  model: {
                                    value: _vm.productStartdate,
                                    callback: function($$v) {
                                      _vm.productStartdate = $$v
                                    },
                                    expression: "productStartdate"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "product-enddate flex flex-row items-center"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "label font-bold pr-4 text-right" },
                          [_vm._v("Product End Date:")]
                        ),
                        _vm.isProductsRefresh
                          ? _c(
                              "div",
                              { staticClass: "date-container" },
                              [
                                _c("DatePicker", {
                                  attrs: {
                                    format: "YYYY-MM-DD",
                                    valueType: "format"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.updateVariationEndDate()
                                    }
                                  },
                                  model: {
                                    value: _vm.productEnddate,
                                    callback: function($$v) {
                                      _vm.productEnddate = $$v
                                    },
                                    expression: "productEnddate"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center justify-evenly pb-4",
                    class: _vm.isProductType === "edit" ? "-ml-100" : ""
                  },
                  [
                    _c("div", { staticClass: "flex flex-row items-center" }, [
                      _c(
                        "label",
                        { staticClass: "label font-bold text-right pr-4" },
                        [_vm._v("CUC Code:")]
                      ),
                      _c(
                        "div",
                        { staticClass: "select-box user-input relative" },
                        [
                          _vm.isCucCodeRefresh
                            ? _c("AutoCompleteDropdown", {
                                attrs: {
                                  options: _vm.cucCodes,
                                  apiPath: _vm.apiPath,
                                  placeholder: "Enter 3 digits of CUC Code",
                                  isAutoFetch: "",
                                  autoFetchType: "CucCodes"
                                },
                                on: { "selected-item": _vm.setSelectedCucCode },
                                model: {
                                  value: _vm.selectedCucCode,
                                  callback: function($$v) {
                                    _vm.selectedCucCode = $$v
                                  },
                                  expression: "selectedCucCode"
                                }
                              })
                            : _vm._e(),
                          _c("Loader", {
                            attrs: { loading: _vm.isCucLoader, size: _vm.size }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "flex flex-row items-center" }, [
                      _c(
                        "label",
                        { staticClass: "label font-bold text-right pr-4" },
                        [_vm._v("Revision:")]
                      ),
                      _c(
                        "div",
                        { staticClass: "select-box user-input relative" },
                        [
                          _vm.isRevisionsRefresh
                            ? _c("AutoCompleteDropdown", {
                                attrs: {
                                  options: _vm.revisions,
                                  limit: _vm.limit
                                },
                                on: {
                                  "selected-item": _vm.setSelectedRevision
                                },
                                model: {
                                  value: _vm.selectedRevision,
                                  callback: function($$v) {
                                    _vm.selectedRevision = $$v
                                  },
                                  expression: "selectedRevision"
                                }
                              })
                            : _vm._e(),
                          _c("Loader", {
                            attrs: {
                              loading: _vm.isRevisionLoader,
                              size: _vm.size
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _c("div", { staticClass: "flex flex-row items-center pb-4" }, [
                  _c(
                    "label",
                    { staticClass: "label font-bold text-right pr-4" },
                    [_vm._v("Description:")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "tooltip select-box relative",
                      class:
                        _vm.isProductType === "edit" ? "w-edit" : "w-create"
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cucDescription,
                            expression: "cucDescription"
                          }
                        ],
                        attrs: { type: "text", disabled: "" },
                        domProps: { value: _vm.cucDescription },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.cucDescription = $event.target.value
                          }
                        }
                      }),
                      _c("Tooltip", {
                        attrs: { hoverText: _vm.cucDescription }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-row items-center justify-evenly pb-4"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "formulation-startdate flex flex-row items-center"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "label font-bold text-right pr-4" },
                          [_vm._v("CUC Start Date:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "date-container" },
                          [
                            _c("DatePicker", {
                              attrs: {
                                format: "YYYY-MM-DD",
                                valueType: "format",
                                disabled:
                                  _vm.selectedCucCode === "" &&
                                  _vm.selectedRevision === ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.updateCucStartDate()
                                }
                              },
                              model: {
                                value: _vm.cucStartDate,
                                callback: function($$v) {
                                  _vm.cucStartDate = $$v
                                },
                                expression: "cucStartDate"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "formulation-enddate flex flex-row items-center"
                      },
                      [
                        _c(
                          "label",
                          { staticClass: "label font-bold text-right pr-4" },
                          [_vm._v("CUC End Date:")]
                        ),
                        _c(
                          "div",
                          { staticClass: "date-container" },
                          [
                            _c("DatePicker", {
                              attrs: {
                                format: "YYYY-MM-DD",
                                valueType: "format",
                                disabled: ""
                              },
                              model: {
                                value: _vm.cucEnddate,
                                callback: function($$v) {
                                  _vm.cucEnddate = $$v
                                },
                                expression: "cucEnddate"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _vm.isProductType === "edit"
                      ? _c(
                          "div",
                          { staticClass: "pl-8" },
                          [
                            _c(
                              "Button",
                              {
                                staticClass: "pr-5",
                                attrs: { disabled: _vm.isAddVariation },
                                on: { click: _vm.getProductVariations }
                              },
                              [
                                _c(
                                  "svg",
                                  {
                                    attrs: {
                                      xmlns: "http://www.w3.org/2000/svg",
                                      viewBox: "0 0 20 20"
                                    }
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d:
                                          "M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                                      }
                                    })
                                  ]
                                ),
                                _vm._v(" Add Product Variations ")
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]),
              _c("div", { staticClass: "table-wrapper" }, [
                _c("table", { staticClass: "pioti-table table-auto" }, [
                  _c("thead", [
                    _c("tr", [
                      _vm.selectedBrand != ""
                        ? _c("th", { staticClass: "actions-chkbx" })
                        : _vm._e(),
                      _c("th", [_vm._v("Country")]),
                      _c("th", [_vm._v("Language")]),
                      _c("th", [_vm._v("Marketed")]),
                      _c("th", [
                        _vm._v("Variation Name"),
                        _c("span", { staticClass: "text-red-600" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _c("th", [
                        _vm._v("Variation Start Date"),
                        _c("span", { staticClass: "text-red-600" }, [
                          _vm._v("*")
                        ])
                      ]),
                      _c("th", [_vm._v("Variation End Date")]),
                      _c("th", [_vm._v("CUC Code")]),
                      _c("th", [_vm._v("Revision")]),
                      _c("th", [_vm._v("CUC Description")]),
                      _c("th", [_vm._v("CUC Start Date")]),
                      _vm.isDeleteEnabled
                        ? _c("th", { staticClass: "actions" })
                        : _vm._e()
                    ])
                  ]),
                  _vm.ProductVariations.length > 0 && _vm.selectedBrand != ""
                    ? _c(
                        "tbody",
                        _vm._l(_vm.ProductVariations, function(
                          variation,
                          index
                        ) {
                          return _c(
                            "tr",
                            {
                              key: index,
                              class: variation.isFormulationDuplicate
                                ? "highlited"
                                : ""
                            },
                            [
                              _vm.selectedBrand != ""
                                ? _c(
                                    "td",
                                    {
                                      staticClass:
                                        "actions-chkbx tooltip table-tooltip"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "checkbox-container",
                                          on: {
                                            mousedown: function($event) {
                                              return _vm.onFormulationCheckboxChange(
                                                variation
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("Checkbox", {
                                            attrs: {
                                              checked: variation.Formulation,
                                              disabled: variation.IsArchived
                                            }
                                          }),
                                          variation.Formulation
                                            ? _c("Tooltip", {
                                                attrs: {
                                                  position: "right",
                                                  hoverText:
                                                    "Formulation will be assigned"
                                                }
                                              })
                                            : variation.IsArchived
                                            ? _c("Tooltip", {
                                                attrs: {
                                                  position: "right",
                                                  hoverText:
                                                    "Formulation is archived"
                                                }
                                              })
                                            : _c("Tooltip", {
                                                attrs: {
                                                  position: "right",
                                                  hoverText:
                                                    "Formulation will not be assigned"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _c("Ellipsis", {
                                    attrs: {
                                      data: variation.Country_Name,
                                      position: "right",
                                      subString: _vm.subString
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _c("Ellipsis", {
                                    attrs: {
                                      data: variation.Language_Name,
                                      position: "right",
                                      subString: _vm.subString
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "checkbox-container ml-2",
                                      on: {
                                        mousedown: function($event) {
                                          return _vm.onVariationCheckboxChange(
                                            variation
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("Checkbox", {
                                        attrs: { checked: variation.Marketed }
                                      }),
                                      variation.Marketed
                                        ? _c("Tooltip", {
                                            attrs: {
                                              position: "left",
                                              hoverText:
                                                "Product will flow to WIOP"
                                            }
                                          })
                                        : _c("Tooltip", {
                                            attrs: {
                                              position: "left",
                                              hoverText:
                                                "Product will not flow to WIOP"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ]
                              ),
                              _c("td", [
                                _vm.isProductType != "add"
                                  ? _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: variation.Variation_Name,
                                          expression: "variation.Variation_Name"
                                        }
                                      ],
                                      attrs: { type: "text", maxlength: "250" },
                                      domProps: {
                                        value: variation.Variation_Name
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            variation,
                                            "Variation_Name",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "date-container" },
                                  [
                                    _c("DatePicker", {
                                      attrs: {
                                        format: "YYYY-MM-DD",
                                        valueType: "format"
                                      },
                                      model: {
                                        value: variation.VariationStartDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            variation,
                                            "VariationStartDate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "variation.VariationStartDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c("td", [
                                _c(
                                  "div",
                                  { staticClass: "date-container" },
                                  [
                                    _c("DatePicker", {
                                      attrs: {
                                        format: "YYYY-MM-DD",
                                        valueType: "format"
                                      },
                                      model: {
                                        value: variation.VariationEndDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            variation,
                                            "VariationEndDate",
                                            $$v
                                          )
                                        },
                                        expression: "variation.VariationEndDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _c("Ellipsis", {
                                    attrs: {
                                      data: variation.CucCode,
                                      subString: _vm.subString,
                                      position: "right"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _c("Ellipsis", {
                                    attrs: {
                                      data: variation.Revision,
                                      subString: _vm.subString,
                                      position: "right"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _c("Ellipsis", {
                                    attrs: {
                                      data: variation.CucDesc,
                                      subString: _vm.subString,
                                      position: "left"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "td",
                                { staticClass: "tooltip table-tooltip" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("formatDate")(
                                          variation.CucStartDate
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _vm.isProductType != "edit" && _vm.isDeleteEnabled
                                ? _c(
                                    "td",
                                    { staticClass: "actions" },
                                    [
                                      _c(
                                        "IconButton",
                                        {
                                          attrs: { buttonType: "delete" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeVariation(index)
                                            }
                                          }
                                        },
                                        [_vm._v("Remove")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              variation.Variation_Id === 0 &&
                              _vm.isProductType === "edit"
                                ? _c(
                                    "td",
                                    { staticClass: "actions" },
                                    [
                                      _c(
                                        "IconButton",
                                        {
                                          attrs: { buttonType: "delete" },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeVariation(index)
                                            }
                                          }
                                        },
                                        [_vm._v("Remove")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isDeleteEnabled && variation.Variation_Id != 0
                                ? _c("td", { staticClass: "actions" })
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.selectedBrand == "" && _vm.isProductType != "edit"
                    ? _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "message-select-brand",
                              attrs: { colspan: "12" }
                            },
                            [_vm._v(" Select Brand to view Variation Details ")]
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.selectedBrand != "" && _vm.ProductVariations.length == 0
                    ? _c("tbody", [
                        _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "message-select-brand",
                              attrs: { colspan: "12" }
                            },
                            [_c("Loader")],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ]),
              _c(
                "div",
                { staticClass: "popup-action__button" },
                [
                  _vm.isModalLoaded
                    ? _c(
                        "Button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                xmlns: "http://www.w3.org/2000/svg",
                                viewBox: "0 0 20 20"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d:
                                    "M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
                                }
                              })
                            ]
                          ),
                          _vm._v(" Save")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }