<template>
  <div>
    <Loader :loading="isLoader" v-if="isLoader" />
    <div
      class="products flex mr-5 mb-5 modal-backdrop"
      :class="isTable ? 'flex-row' : 'flex-col'"
      v-else
    >
      <!-- Message on edit/save -->
      <div class="save-backdrop" :class="isContinueSave ? 'open' : ''">
        <div v-if="isContinueSave" class="continue-save">
          <div class="confirmation-text my-20 pt-5 font-bold text-white">
            Changes made will override the current product details
          </div>
          <div class="flex flex-row flex flex-row justify-between">
            <Button
              buttonType="grey"
              class="cancelBtn"
              @click="discardChanges()"
            >
              Cancel
            </Button>
            <Button buttonType="primary" @click="updateProductData()">
              OK
            </Button>
          </div>
        </div>
      </div>
      <div class="filters-container">
        <div
          class="flex flex-row items-center justify-evenly pb-4"
          :class="isProductType === 'edit' ? '-ml-100' : ''"
        >
          <!--Brand-->
          <div class="flex flex-row items-center">
            <label class="label font-bold text-right pr-4"
              >Brand:<span class="text-red-600">*</span></label
            >
            <div class="select-box user-input relative">
              <AutoCompleteDropdown
                :options="brands"
                v-model="selectedBrand"
                @selected-item="setSelectedBrand"
                :isProductType="isProductType"
                v-if="isBrandRefresh"
                :disabled="isProductType === 'edit'"
              /><Loader :loading="isBrandLoader" :size="dataLoaderSize" />
            </div>
          </div>
          <!--Product-->
          <div class="flex flex-row items-center">
            <label
              class="label font-bold text-right pr-4"
              :class="{ hidden: isTable }"
              >Product Name:<span class="text-red-600">*</span></label
            >
            <div
              class="select-box user-input relative"
              v-if="isProductsRefresh"
            >
              <input
                type="text"
                v-model="selectedProduct"
                :title="selectedProduct"
                :disabled="isProductType === 'edit'"
                maxlength="250"
                @keyup="updateVariationName($event.target.value)"
              />
            </div>
          </div>
        </div>
        <!--Product date range-->
        <div
          class="flex flex-row items-center justify-evenly pb-4"
          :class="isProductType === 'edit' ? '-ml-100' : ''"
        >
          <!--Product start date-->
          <div class="product-startdate flex flex-row items-center">
            <label class="label font-bold pr-4 text-right"
              >Product Start Date:<span class="text-red-600">*</span></label
            >
            <div class="date-container" v-if="isProductsRefresh">
              <DatePicker
                v-model="productStartdate"
                format="YYYY-MM-DD"
                valueType="format"
                :disabled="isProductType === 'edit'"
                @change="updateVariationStartDate()"
              />
            </div>
          </div>
          <!--Product end date-->
          <div class="product-enddate flex flex-row items-center">
            <label class="label font-bold pr-4 text-right"
              >Product End Date:</label
            >
            <div class="date-container" v-if="isProductsRefresh">
              <DatePicker
                v-model="productEnddate"
                format="YYYY-MM-DD"
                valueType="format"
                @change="updateVariationEndDate()"
              />
            </div>
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-evenly pb-4"
          :class="isProductType === 'edit' ? '-ml-100' : ''"
        >
          <!-- CUC code -->
          <div class="flex flex-row items-center">
            <label class="label font-bold text-right pr-4">CUC Code:</label>
            <div class="select-box user-input relative">
              <AutoCompleteDropdown
                :options="cucCodes"
                v-model="selectedCucCode"
                @selected-item="setSelectedCucCode"
                v-if="isCucCodeRefresh"
                :apiPath="apiPath"
                placeholder="Enter 3 digits of CUC Code"
                isAutoFetch
                autoFetchType="CucCodes"
              /><Loader :loading="isCucLoader" :size="size" />
            </div>
          </div>
          <!-- Revision -->
          <div class="flex flex-row items-center">
            <label class="label font-bold text-right pr-4">Revision:</label>
            <div class="select-box user-input relative">
              <AutoCompleteDropdown
                :options="revisions"
                v-model="selectedRevision"
                @selected-item="setSelectedRevision"
                v-if="isRevisionsRefresh"
                :limit="limit"
              /><Loader :loading="isRevisionLoader" :size="size" />
            </div>
          </div>
        </div>
        <!-- Description -->
        <div class="flex flex-row items-center pb-4">
          <label class="label font-bold text-right pr-4">Description:</label>
          <div
            class="tooltip select-box relative"
            :class="isProductType === 'edit' ? 'w-edit' : 'w-create'"
          >
            <input type="text" disabled v-model="cucDescription" /><Tooltip
              :hoverText="cucDescription"
            />
          </div>
        </div>
        <!--Formulation date range-->
        <div class="flex flex-row items-center justify-evenly pb-4">
          <!--Formulation start date-->
          <div class="formulation-startdate flex flex-row items-center">
            <label class="label font-bold text-right pr-4"
              >CUC Start Date:</label
            >
            <div class="date-container">
              <DatePicker
                v-model="cucStartDate"
                format="YYYY-MM-DD"
                valueType="format"
                :disabled="selectedCucCode === '' && selectedRevision === ''"
                @change="updateCucStartDate()"
              />
            </div>
          </div>
          <!--Formulation end date-->
          <div class="formulation-enddate flex flex-row items-center">
            <label class="label font-bold text-right pr-4">CUC End Date:</label>
            <div class="date-container">
              <DatePicker
                v-model="cucEnddate"
                format="YYYY-MM-DD"
                valueType="format"
                disabled
              />
            </div>
          </div>

          <div v-if="isProductType === 'edit'" class="pl-8">
            <Button
              class="pr-5"
              @click="getProductVariations"
              :disabled="isAddVariation"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path
                  d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                />
              </svg>
              Add Product Variations
            </Button>
          </div>
        </div>
      </div>
      <!-- table -->
      <div class="table-wrapper">
        <table class="pioti-table table-auto">
          <thead>
            <tr>
              <th class="actions-chkbx" v-if="selectedBrand != ''"></th>
              <th>Country</th>
              <th>Language</th>
              <th>Marketed</th>
              <th>Variation Name<span class="text-red-600">*</span></th>
              <th>Variation Start Date<span class="text-red-600">*</span></th>
              <th>Variation End Date</th>
              <th>CUC Code</th>
              <th>Revision</th>
              <th>CUC Description</th>
              <th>CUC Start Date</th>
              <th class="actions" v-if="isDeleteEnabled"></th>
            </tr>
          </thead>
          <tbody v-if="ProductVariations.length > 0 && selectedBrand != ''">
            <tr
              v-for="(variation, index) in ProductVariations"
              :key="index"
              :class="variation.isFormulationDuplicate ? 'highlited' : ''"
            >
              <td
                class="actions-chkbx tooltip table-tooltip"
                v-if="selectedBrand != ''"
              >
                <div
                  class="checkbox-container"
                  @mousedown="onFormulationCheckboxChange(variation)"
                >
                  <Checkbox
                    :checked="variation.Formulation"
                    :disabled="variation.IsArchived"
                  />
                  <Tooltip
                    :position="'right'"
                    v-if="variation.Formulation"
                    :hoverText="'Formulation will be assigned'"
                  />
                  <Tooltip
                    :position="'right'"
                    v-else-if="variation.IsArchived"
                    :hoverText="'Formulation is archived'"
                  />
                  <Tooltip
                    :position="'right'"
                    v-else
                    :hoverText="'Formulation will not be assigned'"
                  />
                </div>
              </td>
              <td class="tooltip table-tooltip">
                <Ellipsis
                  :data="variation.Country_Name"
                  :position="'right'"
                  :subString="subString"
                />
              </td>
              <td class="tooltip table-tooltip">
                <Ellipsis
                  :data="variation.Language_Name"
                  :position="'right'"
                  :subString="subString"
                />
              </td>
              <td class="tooltip table-tooltip">
                <div
                  class="checkbox-container ml-2"
                  @mousedown="onVariationCheckboxChange(variation)"
                >
                  <Checkbox :checked="variation.Marketed" /><Tooltip
                    :position="'left'"
                    v-if="variation.Marketed"
                    :hoverText="'Product will flow to WIOP'"
                  />
                  <Tooltip
                    :position="'left'"
                    v-else
                    :hoverText="'Product will not flow to WIOP'"
                  />
                </div>
              </td>
              <td>
                <input
                  type="text"
                  v-if="isProductType != 'add'"
                  maxlength="250"
                  v-model="variation.Variation_Name"
                />
              </td>
              <td>
                <div class="date-container">
                  <DatePicker
                    v-model="variation.VariationStartDate"
                    format="YYYY-MM-DD"
                    valueType="format"
                  />
                </div>
              </td>
              <td>
                <div class="date-container">
                  <DatePicker
                    v-model="variation.VariationEndDate"
                    format="YYYY-MM-DD"
                    valueType="format"
                  />
                </div>
              </td>

              <td class="tooltip table-tooltip">
                <Ellipsis
                  :data="variation.CucCode"
                  :subString="subString"
                  :position="'right'"
                />
              </td>
              <td class="tooltip table-tooltip">
                <Ellipsis
                  :data="variation.Revision"
                  :subString="subString"
                  :position="'right'"
                />
              </td>
              <td class="tooltip table-tooltip">
                <Ellipsis
                  :data="variation.CucDesc"
                  :subString="subString"
                  :position="'left'"
                />
              </td>
              <td class="tooltip table-tooltip">
                {{ variation.CucStartDate | formatDate }}
              </td>
              <td
                class="actions"
                v-if="isProductType != 'edit' && isDeleteEnabled"
              >
                <IconButton
                  :buttonType="'delete'"
                  @click="removeVariation(index)"
                  >Remove</IconButton
                >
              </td>
              <td
                class="actions"
                v-if="variation.Variation_Id === 0 && isProductType === 'edit'"
              >
                <IconButton
                  :buttonType="'delete'"
                  @click="removeVariation(index)"
                  >Remove</IconButton
                >
              </td>
              <td
                class="actions"
                v-if="isDeleteEnabled && variation.Variation_Id != 0"
              ></td>
            </tr>
          </tbody>
          <tbody v-if="selectedBrand == '' && isProductType != 'edit'">
            <tr>
              <td colspan="12" class="message-select-brand">
                Select Brand to view Variation Details
              </td>
            </tr>
          </tbody>
          <tbody v-if="selectedBrand != '' && ProductVariations.length == 0">
            <tr>
              <td colspan="12" class="message-select-brand">
                <Loader />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- table -->
      <!--Buttons-->
      <div class="popup-action__button">
        <Button type="primary" @click="save()" v-if="isModalLoaded"
          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path
              d="M0 2C0 .9.9 0 2 0h14l4 4v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm5 0v6h10V2H5zm6 1h3v4h-3V3z"
            />
          </svg>
          Save</Button
        >

        <!--  :disabled="!isSave" -->
      </div>
    </div>
  </div>
</template>
<script>
import "../../filters";
import DatePicker from "vue2-datepicker";
import { mapGetters, mapMutations } from "vuex";
import loggerService from "../../services/logger";
import authentication from "../../authentication";
import formulationMappingService from "../../services/formulationMappings";
import productService from "../../services/products";

export default {
  name: "product",
  components: {
    Loader: () => import(/* webpackPrefetch: true */ "./../common/Loader"),
    AutoCompleteDropdown: () =>
      import(/* webpackPrefetch: true */ "./../common/AutoCompleteDropdown"),
    Button: () => import(/* webpackPrefetch: true */ "./../common/Button"),
    Checkbox: () => import(/* webpackPrefetch: true */ "../common/Checkbox"),
    IconButton: () =>
      import(/* webpackPrefetch: true */ "../common/IconButton"),
    Ellipsis: () => import(/* webpackPrefetch: true */ "../common/Ellipsis"),
    Tooltip: () => import(/* webpackPrefetch: true */ "../common/Tooltip"),
    DatePicker,
  },
  props: {
    isTable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    subString: 10,
    mandatoryReturnValue: false,
    dateValidation: false,
    variationStartDateValidation: true,
    variationEndDateValidation: true,
    cucStartDateValidation: true,
    productStartdate: "",
    productEnddate: "",
    isLoader: true,
    size: "15px",
    isBrandLoader: false,
    isProductLoader: false,
    dataLoaderSize: "5px",
    isBrandRefresh: true,
    isProductsRefresh: true,
    displayMode: "horizantal",
    brands: [],
    selectedBrand: "",
    brandId: "",
    selectedProduct: "",
    productId: "",
    isContinueSave: false,
    createdBy: "",
    createdDate: "",
    isModalLoaded: false,
    ProductVariations: [],
    isAddVariation: false,
    variations: [],
    limit: 1,
    isCucLoader: false,
    isRevisionLoader: false,
    isCucCodeRefresh: true,
    isRevisionsRefresh: true,
    selectedCucCode: "",
    selectedRevision: "",
    cucCodes: [],
    revisions: [],
    cucDescription: "",
    cucStartDate: "",
    cucEnddate: "",
    mappedVariations: [],
    isRevisionSelected: false,
    originalProductName: "",
    isNewVariationsLoaded: false,
  }),
  created() {
    this.getBrands();
    setTimeout(() => {
      this.isModalLoaded = true;
    }, 500);
    if (this.isProductType != "edit") {
      this.isLoader = false;
    }
  },
  computed: {
    apiPath() {
      return `FormulationMapping/GetCUCCodesForFilter/${authentication.getUserEmail()}/`;
    },
    userEmail() {
      if (!authentication.isAuthenticated) {
        return null;
      }
      return authentication.getUserEmail();
    },
    ...mapGetters({
      isProductType: "GET_IS_PRODUCT_TYPE",
      isProductListed: "GET_IS_PRODUCT_LISTED",
      cucStatus: "GET_STATUS",
      userInfo: "GET_USER_ROLE",
      countries: "GET_COUNTRIES",
      brand: "GET_BRAND_IDS",
      productInfo: "GET_UPDATE_PRODUCT",
    }),
    isSave() {
      this.validateMandatoryFields();
      return this.mandatoryReturnValue && this.dateValidation;
    },
    isDeleteEnabled() {
      return this.ProductVariations.length > 1 ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      updateBrandIds: "SET_BRAND_IDS",
      addProduct: "SET_ADD_PRODUCT",
    }),
    updateVariationStartDate() {
      this.ProductVariations.map((variation) => {
        variation.VariationStartDate = this.productStartdate;
      });
    },
    updateVariationEndDate() {
      this.ProductVariations.map((variation) => {
        variation.VariationEndDate = this.productEnddate;
      });
    },
    updateVariationName(name) {
      this.ProductVariations.map((variation) => {
        variation.Variation_Name = name;
      });
    },
    updateCucStartDate() {
      if (this.isProductType != "edit") {
        this.ProductVariations.map((variation) => {
          variation.CucStartDate = this.cucStartDate;
        });
      }
    },
    clearSelected() {
      this.selectedBrand = "";
    },
    setSelectedBrand(brand) {
      if (brand === true) {
        this.selectedBrand = "";
        this.ProductVariations = [];
        return;
      }
      this.isProductLoader = true;
      setTimeout(() => {
        this.isProductLoader = false;
      }, 500);
      this.selectedBrand = brand.Value;
      this.brandId = brand.Id;
      this.isProductRefresh();
      if (this.isProductType != "edit") {
        this.getCountriesAndLanguages();
      }
    },
    setSelectedCucCode(cucCode) {
      if (cucCode === true) {
        this.selectedCucCode = "";
        this.selectedRevision = "";
        this.cucDescription = "";
        this.cucStartDate = "";
        this.revisions = [];
        if (this.isProductType === "edit") {
          if (this.isAddVariation) {
            this.getProductVariations();
          } else {
            this.getMappedVariationsOnEdit();
          }
        } else {
          this.getMappedCountriesAndLanguages();
        }
        this.isRevisionRefresh();
        return;
      }
      this.selectedCucCode = cucCode.Value;
      this.getRevisions(cucCode.Value);
    },
    async getRevisions(code) {
      try {
        const { data } = await formulationMappingService.getCUCRevisions(code);
        this.revisions = data;
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      } catch (error) {
        loggerService.logError(error);
        this.revisions = [];
        this.isRevisionLoader = false;
        this.isRevisionRefresh();
      }
    },
    setSelectedRevision(revision) {
      if (revision === true) {
        this.selectedRevision = "";
        this.cucDescription = "";
        if (this.isProductType === "edit") {
          if (this.isAddVariation) {
            this.getProductVariations();
          } else {
            this.getMappedVariationsOnEdit();
          }
        } else {
          this.getMappedCountriesAndLanguages();
        }
        return;
      }
      this.selectedRevision = revision.Value;
      this.cucDescription = revision.Info;

      if (this.isProductType != "edit") {
        this.getMappedCountriesAndLanguages();
        this.ProductVariations.map((variation) => {
          variation.CucCode = this.selectedCucCode;
          variation.Revision = this.selectedRevision;
          variation.CucDesc = this.cucDescription;
        });
      } else {
        if (this.isAddVariation) {
          this.getProductVariations();
        } else {
          this.getMappedVariationsOnEdit();
        }
      }
    },
    async getCountriesAndLanguages() {
      const payLoad = {
        brand: this.selectedBrand,
        cucCode: this.selectedCucCode,
        revision: this.selectedRevision,
      };
      const {
        data,
      } = await productService.getCountriesAndLanguagesByUserAndBrand(payLoad);

      this.ProductVariations = [];
      data.map((country) => {
        let variation = {};
        country.Languages.map((language) => {
          variation = {
            Variation_Id: 0,
            Product_Id: 0,
            Variation_Name: "",
            Country_Id: country.CountryId,
            Language_Id: language.LanguageId,
            CreatedBy: authentication.getUserEmail(),
            CreatedDate: this.todayDate(),
            Country_Name: country.Country,
            Language_Name: language.Language,
            UpdatedBy: authentication.getUserEmail(),
            UpdatedDate: this.todayDate(),
            VariationStartDate: "",
            VariationEndDate: "",
            Marketed: false,
            Formulation: false,
            CucCode: "",
            Revision: "",
            CucDesc: "",
            CucStartDate: "",
            CucEndDate: "",
            IsArchived: language.Disabled,
          };
          this.ProductVariations.push(variation);
        });
      });
      if (
        this.ProductVariations.length === 1 &&
        this.isProductType === "create"
      ) {
        this.ProductVariations[0].Marketed = true;
      }
    },
    async getLanguages(country) {
      try {
        const { data } = await formulationMappingService.getLanguagesByCountry(
          country
        );

        this.languages = data;
      } catch (error) {
        loggerService.logError(error);
        this.languages = [];
      }
    },
    async getBrands() {
      if (this.isProductType == "edit") {
        this.isLoader = false;
        this.getVariationsOnEdit();
      } else {
        try {
          const { data } = await formulationMappingService.getBrands();

          this.brands = data;
          this.isBrandLoader = false;
          this.isLoader = false;
          this.isBrandsRefresh();
        } catch (error) {
          loggerService.logError(error);
          this.brands = [];
          this.isBrandLoader = false;
          this.isBrandsRefresh();
        }
      }
    },

    async getMappedCountriesAndLanguages() {
      const payLoad = {
        brand: this.selectedBrand,
        cucCode: this.selectedCucCode === "" ? null : this.selectedCucCode,
        revision: this.selectedRevision === "" ? null : this.selectedRevision,
      };
      const {
        data,
      } = await productService.getCountriesAndLanguagesByUserAndBrand(payLoad);

      this.mappedVariations = [];
      data.map((country) => {
        let variation = {};
        country.Languages.map((language) => {
          variation = {
            Country_Id: country.CountryId,
            Language_Id: language.LanguageId,
            IsArchived: language.Disabled,
          };
          this.mappedVariations.push(variation);
        });
      });
      let updatedMappedVariations = this.updatedMappedVariations(
        this.ProductVariations,
        this.mappedVariations
      );
      this.ProductVariations = [];
      this.ProductVariations = updatedMappedVariations;
    },
    async getMappedVariationsOnEdit() {
      const countryIds = this.countries.map((country) => country.Id);
      let payLoad = {
        productId:
          this.brand.productId === "" || this.brand.productId === undefined
            ? null
            : this.brand.productId,
        variationId:
          this.brand.variationId === "" || this.brand.variationId === undefined
            ? null
            : this.brand.variationId,
        countryId:
          countryIds === "" || countryIds === [] ? null : countryIds.join(),
        cucCode: this.selectedCucCode === "" ? null : this.selectedCucCode,
        revision: this.selectedRevision === "" ? null : this.selectedRevision,
      };
      try {
        const { data } = await formulationMappingService.getProductFormulation(
          payLoad
        );

        let updatedMappedVariations = [];
        this.ProductVariations.forEach((item) => {
          let isVariationArchived = false;
          let CucCode = item.cucCode;
          let Revision = item.revision;
          let CucDesc = item.cucDescription;
          let CucStartDate = item.cucStartDate;
          data.ProductVariations.filter((variation) => {
            if (
              item.Country_Id == variation.Country_Id &&
              item.Language_Id == variation.Language_Id
            ) {
              if (this.selectedCucCode != "" && this.selectedRevision != "") {
                isVariationArchived = variation.IsArchived;
                CucCode = variation.CucCode;
                Revision = variation.Revision;
                CucDesc = variation.CucDesc;
                CucStartDate = variation.CucStartDate;
              }
            }
          });
          item.IsArchived = isVariationArchived;
          item.cucCode = CucCode;
          item.revision = Revision;
          item.cucDescription = CucDesc;
          item.cucStartDate = CucStartDate;
          if (this.selectedCucCode != "" && this.selectedRevision != "") {
            if (isVariationArchived) {
              item.Formulation = false;
            } else {
              item.Formulation = true;
            }
          } else {
            item.Formulation = false;
          }
          updatedMappedVariations.push(item);
        });
        this.ProductVariations = [];
        this.ProductVariations = updatedMappedVariations;
      } catch (error) {
        loggerService.logError(error);
      }
    },
    async getVariationsOnEdit() {
      this.isLoader = true;
      const countryIds = this.countries.map((country) => country.Id);
      let payLoad = {
        productId:
          this.brand.productId === "" || this.brand.productId === undefined
            ? null
            : this.brand.productId,
        variationId:
          this.brand.variationId === "" || this.brand.variationId === undefined
            ? null
            : this.brand.variationId,
        countryId:
          countryIds === "" || countryIds === [] ? null : countryIds.join(),
        cucCode: this.selectedCucCode === "" ? null : this.selectedCucCode,
        revision: this.selectedRevision === "" ? null : this.selectedRevision,
      };
      try {
        const { data } = await formulationMappingService.getProductFormulation(
          payLoad
        );

        // for update product
        this.isLoader = false;
        this.brands = [
          {
            Id: data.BrandId,
            Value: data.BrandName,
          },
        ];
        this.productId = data.ProductId;
        this.selectedBrand = data.BrandId;
        this.selectedProduct = data.ProductName;
        this.originalProductName = data.ProductName;
        this.productStartdate = data.ProductStartDate;
        this.productEnddate = data.ProductEndDate;
        this.ProductVariations = data.ProductVariations;
        this.CreatedDate = data.CreatedDate;
        this.CreatedBy = data.CreatedBy;
      } catch (error) {
        loggerService.logError(error);
      }
    },
    async getProductVariations() {
      this.isAddVariation = true;
      let payLoad = {
        emailId: this.userEmail,
        brandName: this.selectedBrand,
        productName: this.originalProductName,
        CucCode: this.selectedCucCode === "" ? null : this.selectedCucCode,
        Revision: this.selectedRevision === "" ? null : this.selectedRevision,
      };
      try {
        const {
          data,
        } = await formulationMappingService.getCountriesAndLanguagesByBrandAndUser(
          payLoad
        );

        // for update product
        this.isLoader = false;
        this.variations = data;
        let returnedVariations = this.updatedVariations(this.productId, data);
        let modifiedVaririons = [];
        if (this.isNewVariationsLoaded) {
          this.ProductVariations.forEach((item) => {
            this.variations.filter((variation) => {
              if (
                item.Country_Id == variation.Country_Id &&
                item.Language_Id == variation.Language_Id
              ) {
                modifiedVaririons.push(variation);
              }
            });
          });
        } else {
          modifiedVaririons = [
            ...returnedVariations.oldVariations,
            ...returnedVariations.newVariations,
          ];
        }

        if (
          returnedVariations.newVariations === [] ||
          returnedVariations.newVariations.length === 0
        ) {
          loggerService.logInfo600(
            "There is no new variations available for the selected brand"
          );
        }
        let updatedMappedVariations = [];
        modifiedVaririons.forEach((item) => {
          let variationName = item.Variation_Name;
          let marketed = item.Marketed;
          let variationStartDate = item.VariationStartDate;
          let variationEndDate = item.variationEndDate;
          if (
            item.Variation_Id === 0 &&
            (variationStartDate === null || variationStartDate === "")
          ) {
            variationStartDate = this.productStartdate;
          }
          this.ProductVariations.filter((variation) => {
            if (
              item.Country_Id == variation.Country_Id &&
              item.Language_Id == variation.Language_Id
            ) {
              variationName = variation.Variation_Name;
              marketed = variation.Marketed;
              variationStartDate = variation.VariationStartDate;
              variationEndDate = variation.VariationEndDate;
            }
          });
          item.Variation_Name = variationName;
          item.Marketed = marketed;
          item.VariationStartDate = variationStartDate;
          item.VariationEndDate = variationEndDate;

          if (this.selectedCucCode != "" && this.selectedRevision != "") {
            if (item.IsArchived) {
              item.Formulation = false;
            } else {
              item.Formulation = true;
            }
          } else {
            item.Formulation = false;
          }
          updatedMappedVariations.push(item);
        });
        this.ProductVariations = updatedMappedVariations;
        this.isNewVariationsLoaded = true;
      } catch (error) {
        loggerService.logError(error);
        this.isLoader = false;
        this.ProductVariations = "";
      }
    },
    updatedVariations(val, variations) {
      let newVariations = variations.filter((x) => x.Product_Id != val);
      let oldVariations = variations.filter((x) => x.Product_Id === val);
      let result = {
        newVariations: newVariations,
        oldVariations: oldVariations,
      };
      return result;
    },
    removeVariation(index) {
      this.ProductVariations.splice(index, 1);
      if (
        this.ProductVariations.length === 1 &&
        this.isProductType === "create"
      ) {
        this.ProductVariations[0].Marketed = true;
      }
    },
    updatedMappedVariations(productVariations, data) {
      let updatedMappedVariations = [];
      productVariations.forEach((item) => {
        let isVariationArchived = false;
        data.filter((variation) => {
          if (
            item.Country_Id == variation.Country_Id &&
            item.Language_Id == variation.Language_Id
          ) {
            if (
              this.selectedCucCode != "" &&
              this.selectedCucCode != null &&
              this.selectedRevision != "" &&
              this.selectedRevision != null
            ) {
              isVariationArchived = variation.IsArchived;
            }
          }
        });
        item.IsArchived = isVariationArchived;
        if (
          this.selectedCucCode != "" &&
          this.selectedCucCode != null &&
          this.selectedRevision != "" &&
          this.selectedRevision != null
        ) {
          if (isVariationArchived) {
            item.Formulation = false;
          } else {
            item.Formulation = true;
          }
        } else {
          item.Formulation = false;
        }
        updatedMappedVariations.push(item);
      });
      return updatedMappedVariations;
    },
    validateMandatoryFields() {
      if (
        this.brandId != "" &&
        this.selectedProduct != "" &&
        this.productStartdate != "" &&
        this.productStartdate != null
      )
        this.mandatoryReturnValue = true;
      else this.mandatoryReturnValue = false;
      this.dateValidation = this.validateDate(
        this.productStartdate,
        this.productEnddate
      );
      this.dateValidation = this.validateDate(
        this.cucStartdate,
        this.cucEnddate
      );
      let BreakException = {};
      let inValidVariations = [];
      try {
        this.ProductVariations.map((variation) => {
          if (
            variation.Variation_Name != "" &&
            variation.Variation_Name != null &&
            variation.VariationStartDate != null &&
            variation.VariationStartDate != ""
          )
            this.mandatoryReturnValue = true;
          else this.mandatoryReturnValue = false;
          if (!this.mandatoryReturnValue) {
            throw BreakException;
          }
          this.dateValidation = this.validateDate(
            variation.VariationStartDate,
            variation.VariationEndDate
          );
          // to come out of map loop using BreakException when one variation date validation fails
          if (!this.dateValidation) {
            throw BreakException;
          }
          // cuc start date validation
          if (this.isProductType === "edit" && variation.Formulation) {
            if (
              this.cucStartDate != "" &&
              this.cucStartDate != null &&
              variation.CucStartDate != "" &&
              variation.CucStartDate != null
            ) {
              this.cucStartDateValidation = this.compareCucDate(
                this.cucStartDate,
                variation.CucStartDate
              );
              if (!this.cucStartDateValidation) {
                let obj = {
                  variationId: variation.Variation_Id,
                  countryId: variation.Country_Id,
                  languageId: variation.Language_Id,
                  errorType: "cucStartDate",
                };
                inValidVariations.push(obj);
              }
            }
          }
          // cuc start date and variation start date validation
          if (variation.Formulation) {
            if (
              this.cucStartDate != "" &&
              this.cucStartDate != null &&
              variation.VariationStartDate != "" &&
              variation.VariationStartDate != null
            ) {
              this.isRevisionSelected = false;
              this.variationStartDateValidation = this.compareDate(
                this.cucStartDate,
                variation.VariationStartDate
              );
              if (!this.variationStartDateValidation) {
                let obj = {
                  variationId: variation.Variation_Id,
                  countryId: variation.Country_Id,
                  languageId: variation.Language_Id,
                  errorType: "variationStartDate",
                };
                inValidVariations.push(obj);
              }
            } else if (
              (this.selectedRevision != "" &&
              this.cucStartDate === "") || (this.selectedRevision != "" &&
              this.cucStartDate === null)
            ) {
              this.isRevisionSelected = true;
              this.variationStartDateValidation = false;
            }
          }
          // variation end date and cuc end date validation
          if (
            variation.VariationEndDate != "" &&
            variation.VariationEndDate != null &&
            this.cucEnddate != "" &&
            this.cucEnddate != null
          ) {
            this.variationEndDateValidation = this.compareDate(
              variation.VariationEndDate,
              this.cucEnddate
            );
            if (!this.variationEndDateValidation) {
              let obj = {
                variationId: variation.Variation_Id,
                countryId: variation.Country_Id,
                languageId: variation.Language_Id,
                errorType: "variationEndDate",
              };
              inValidVariations.push(obj);
            }
          }
        });
        let errorVariations = [];
        this.ProductVariations.forEach((item) => {
          let isError = false;
          inValidVariations.map((variation) => {
            if (
              item.Country_Id == variation.countryId &&
              item.Language_Id == variation.languageId &&
              item.Variation_Id === variation.variationId
            ) {
              isError = true;
            }
          });
          item.isFormulationDuplicate = isError;
          errorVariations.push(item);
        });
        this.ProductVariations = errorVariations;
        let error = "";
        inValidVariations.map((varition) => {
          error = varition.errorType;
          if (error === "cucStartDate") {
            this.cucStartDateValidation = false;
          }
          if (error === "variationStartDate") {
            this.variationStartDateValidation = false;
          }
          if (error === "variationEndDate") {
            this.variationEndDateValidation = false;
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    },
    validationMessage() {
      this.validateMandatoryFields();
      if (!this.mandatoryReturnValue) {
        loggerService.logError600("Mandatory fields can't be empty");
      }
      if (!this.dateValidation) {
        loggerService.logError600("End date should be greater than start date");
      }
      if (!this.variationStartDateValidation) {
        if (this.isRevisionSelected) {
          loggerService.logError600("Please select CUC Start Date");
        } else {
          loggerService.logError600(
            "CUC Start Date has to be greater than Variation Start Date"
          );
        }
      }
      if (!this.variationEndDateValidation) {
        loggerService.logError600(
          "Variation End Date has to be greater than or equal to the CUC End Date"
        );
      }
      if (!this.cucStartDateValidation) {
        loggerService.logError600(
          "CUC Start Date has to be greater than current CUC Start Date"
        );
      }

      return (
        this.mandatoryReturnValue &&
        this.dateValidation &&
        this.variationStartDateValidation &&
        this.variationEndDateValidation &&
        this.cucStartDateValidation
      );
    },
    save() {
      if (this.validationMessage()) {
        //save on create
        if (this.isProductType === "create") {
          this.createNewProduct();
        }
        // save on edit
        else {
          this.isContinueSave = true;
        }
      }
    },
    todayDate() {
      return new Date().toISOString().slice(0, 10);
    },
    async createNewProduct() {
      try {
        let newProductItem = {
          ProductId: 0,
          ProductName: this.selectedProduct,
          BrandId: parseInt(this.brandId),
          BrandName: this.selectedBrand,
          ProductStartDate: this.productStartdate,
          ProductEndDate: this.productEnddate,
          CreatedBy: authentication.getUserEmail(),
          CreatedDate: this.todayDate(),
          ProductVariations: this.ProductVariations,
          CucCode: this.selectedCucCode,
          Revision: this.selectedRevision,
          CucStartDate: this.cucStartDate,
        };
        //condition to write for existing product
        const {
          data,
        } = await formulationMappingService.createProductByBrandName(
          newProductItem
        );

        if (data.Error) {
          this.$emit("on-close");
          loggerService.logError600(data.Error);
          return;
        }

        if (data[0].IsDuplicate) {
          loggerService.logError600("Product already exist in the system");
        } else {
          this.$emit("on-close");
          let newProduct = [];
          data[0].NewProductName.map((item) => {
            newProduct.push({
              Id: data[0].Product_Id,
              Value: item.Product_Name,
              CountryId: item.CountryId,
            });
          });
          this.addProduct(newProduct);
          loggerService.logSuccess("Product Created Successfully");
        }
      } catch (error) {
        loggerService.logError(error);
      }
    },
    async updateProductData() {
      try {
        let updateProduct = {
          ProductId: this.productId,
          ProductName: this.selectedProduct,
          BrandId: parseInt(this.brandId),
          BrandName: this.selectedBrand,
          ProductStartDate: this.productStartdate,
          ProductEndDate: this.productEnddate,
          UpdatedBy: authentication.getUserEmail(),
          UpdatedDate: this.todayDate(),
          CreatedBy: this.CreatedBy,
          CreatedDate: this.CreatedDate,
          CucCode: this.selectedCucCode,
          Revision: this.selectedRevision,
          CucStartDate: this.cucStartDate,
          ProductVariations: this.ProductVariations,
        };
        const {
          data,
        } = await formulationMappingService.updateProductAndVariations(
          updateProduct,
          this.isAddVariation
        );

        if (data.Error) {
          this.$emit("on-close");
          loggerService.logError600(data.Error);
          return;
        }

        if (data[0].IsDuplicate) {
          loggerService.logError600("Product already exist in the system");
        } else if (data[0].IsFormulationDuplicate) {
          this.isContinueSave = false;
          let result = this.ProductVariations.map((item) => {
            let obj1 = Object.assign({}, item);
            data.filter((variation) => {
              if (item.Variation_Id == variation.Variation_Id) {
                obj1.isFormulationDuplicate = true;
              } else {
                obj1.isFormulationDuplicate = false;
              }
            });
            return obj1;
          });
          this.ProductVariations = result;
          loggerService.logError600("Variation is already formulated");
        } else {
          this.$emit("on-close");
          const assignFormulation = {
            brandIds: this.brandIds,
            productId: data[0].Product_Id,
            productName: data[0].Product_Name,
          };
          this.updateBrandIds(assignFormulation);
          loggerService.logSuccess("Product Updated Successfully");
        }
      } catch (error) {
        loggerService.logError(error);
      }
    },
    validateDate(startDate, toDate) {
      const fromdate = startDate;
      const todate = toDate;
      if (this.compareDate(fromdate, todate)) {
        return false;
      } else {
        return true;
      }
    },
    convertDate(str) {
      let parts;
      if (str === null) return;
      if (str.split("-").length > 1) parts = str.split("-");
      else if (str.split("/").length > 1) parts = str.split("/");
      return new Date(parts[0] + "-" + parts[1] + "-" + parts[2]);
    },
    compareDate(fromDate, toDate) {
      if (
        fromDate != "" &&
        toDate != "" &&
        fromDate != undefined &&
        toDate != undefined
      ) {
        if (this.convertDate(fromDate) >= this.convertDate(toDate)) {
          return true;
        } else {
          return false;
        }
      }
    },
    compareCucDate(fromDate, toDate) {
      if (
        fromDate != "" &&
        toDate != "" &&
        fromDate != undefined &&
        toDate != undefined
      ) {
        if (this.convertDate(fromDate) > this.convertDate(toDate)) {
          return true;
        } else {
          return false;
        }
      }
    },
    onVariationCheckboxChange(variation) {
      variation.Marketed = variation.Marketed ? false : true;
    },
    onFormulationCheckboxChange(variation) {
      if (!variation.IsArchived) {
        if (this.selectedCucCode === "" && this.selectedRevision === "") {
          loggerService.logInfo600(
            "Please select CUC Code and Revision to make the Formulation"
          );
        } else if (this.selectedRevision === "") {
          loggerService.logInfo600(
            "Please select Revision to make the Formulation"
          );
        } else if (this.selectedCucCode === "") {
          loggerService.logInfo600(
            "Please select CUC Code to make the Formulation"
          );
        } else {
          variation.Formulation = variation.Formulation ? false : true;
        }
      }
    },
    discardChanges() {
      this.isContinueSave = false;
      loggerService.logInfo600("The changes you have made will be discarded");
    },
    isBrandsRefresh() {
      this.isBrandRefresh = false;
      this.$nextTick(() => {
        this.isBrandRefresh = true;
      });
    },
    isProductRefresh() {
      this.isProductsRefresh = false;
      this.$nextTick(() => {
        this.isProductsRefresh = true;
      });
    },
    isRevisionRefresh() {
      this.isRevisionsRefresh = false;
      this.$nextTick(() => {
        this.isRevisionsRefresh = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filters-container {
  margin: 0 auto;
  .label {
    width: 150px;
  }
}

.user-input {
  width: 210px;
}
.products {
  .-ml-100 {
    margin-left: -100px;
  }
  .select-box {
    .v-spinner {
      position: absolute;
      top: -4px;
    }
  }
  .save-backdrop.open {
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 9;
    .continue-save {
      background: #ed6663;
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
      border-radius: 0.3rem;
      transition: 0.5s;
      position: relative;
      padding: 10px;
    }
  }
}
.table-wrapper {
  .date-container {
    .mx-datepicker {
      width: 120px;
    }
    .mx-input {
      border-radius: 2px !important;
      border: 1px solid #d2d6de !important;
      height: 30px !important;
    }
  }
}
input {
  border: 1px solid #d2d6de !important;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
  background: #fff;
  height: 30px;
  opacity: 0.8;
  width: 100%;
}
::-ms-backdrop,
.product-enddate {
  margin-left: 31px;
}
::-ms-backdrop,
.product-date-range {
  margin-left: 10px;
}
.product-date-range .date-container .mx-datepicker {
  width: 186px;
}
.table-wrapper {
  max-height: 200px;
  min-height: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  .highlited {
    background: #f9cbe2;
    &:hover {
      background: #f7b0d3 !important;
    }
  }
  table {
    .actions-chkbx {
      min-width: 30px;
      max-width: 30px;
    }
  }
}
.message-select-brand {
  text-align: center !important;
  font-weight: bold;
  padding: 10px !important;
}
table {
  border-radius: 3px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
  tr {
    th,
    td {
      min-width: auto;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7) {
        min-width: 130px;
        max-width: 130px;
      }
    }
  }
}
.w-create {
  width: 80%;
}
.w-edit {
  width: 73%;
}
</style>
